import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Table, Tag, Space, Button, Modal, Form, Input, Badge, Card, Row, Col, InputNumber, Select, Result } from 'antd';
import { deleteTag, getAllTags, updateTag, createTag } from 'api/api-tags';
import {DeleteOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, PictureOutlined } from '@ant-design/icons';


import useOptions from 'modules/options/options.hook';
const { Option } = Select;

const confirm = Modal.confirm;

interface Item {
    id: string;
    tag_type: string;
    tag_name: string;
    tag_description: string;
}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	form:any;
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'photo-picker' | 'price' | 'select' | 'tag';
    record: Item;
    index: number;
    children: React.ReactNode;
	options?:any[],
  }
  
const EditableCell: React.FC<EditableCellProps> = ({
	form,
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	options,
	...restProps
}) => {
	let inputNode = <Input />;
	if(inputType == 'number'){
		inputNode = <InputNumber />;
	}

	if(inputType == 'select'){
		console.log('select options ==>', options);
		inputNode = <Select>{options?options.map((ele)=><Option value = {ele.id}>{ele.tag_name}</Option>):[]}</Select>;
	}

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};



const ActionCell = ({ item, editingId, onChange, onEditing, form }: any) => {
	const [isRequesting, setIsRequesting] = useState(false);
	const onDeleteBtn = () => {
		confirm({
			centered:true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps:{
				style:{
					backgroundColor:'#fff'
				}
			},
			cancelButtonProps: {
				style:{
					backgroundColor:'#0ab068', color:'#fff'
				}
			},
			onOk() {
				setIsRequesting(true);
				deleteTag(item.id).then((res:any)=>{
					setIsRequesting(false);
					onChange();
				}).catch((err:any)=>{
					setIsRequesting(false);
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const onClickEditBtn = () => {
		onEditing(item.id);
		form.setFieldsValue(item);
	};

	const onSave = () => {
		setIsRequesting(true);
		console.log('onSave ==>', form.getFieldsValue());
		updateTag(item.id, form.getFieldsValue()).then(res=>{
			setIsRequesting(false);
			onEditing('');
		}).catch(err=>{
			console.log(err);
			setIsRequesting(false);
			onEditing('');
		});
	};

	const onCancel = () => {
		onEditing('');
	};

	if(editingId == item.id){
		return (
			<Space size="middle">
				<Button loading={isRequesting} icon={<CheckCircleOutlined />} onClick={onSave} />
				<Button loading={isRequesting} icon={<CloseCircleOutlined/>} onClick={onCancel} />
			</Space>
		);    
	}

	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<EditOutlined />} onClick={onClickEditBtn} />
			<Button loading={isRequesting} icon={<DeleteOutlined/>} onClick={onDeleteBtn} />
		</Space>
	);
};



const Index = () => {

	const [form] = Form.useForm();
	const [createForm] = Form.useForm();
	const [editingId, setEditingId] = useState('');
	const [isModalVisible, setModalVisible] = useState(false);
	const [isRequesting, setIsRequesting] = useState(false);
	const [query, setQuery] = useState('');
	const { options: { tags }, getAllTagsSaga} = useOptions();

	console.log('tags ===>',tags);

	const columns:any = [
		{
			title: 'Tag Type',
			dataIndex: 'tag_type',
			editable:true,
			sorter: (a:any, b:any) => {
				if (a.tag_type < b.tag_type)
					return -1;
				if (a.tag_type > b.tag_type)
					return 1;
				return 0;
			}
		},
		{
			title: 'Tag Name',
			dataIndex: 'tag_name',
			editable:true,
			sorter: (a:any, b:any) => {
				if (a.tag_name < b.tag_name)
					return -1;
				if (a.tag_name > b.tag_name)
					return 1;
				return 0;
			}
		},
		{
			title: 'Parent Tag',
			dataIndex: 'parent_tag',
			inputType:'select',
			options:tags.filter((ele:any)=>ele.tag_type == 'state'),
			editable:true,
			render: (val:any, item:any)=>{
				let result = '';
				tags.forEach((element:any) => {
					if(element.id == val){
						result = element.tag_name;
					}
				});
				return result;
			}
		},
		{
			title: 'Description',
			dataIndex: 'tag_description',
			editable:true
		},
		{
			title: '',
			key: 'action',
			width: '20%',
			render: (text: string, record: any) => (
				<ActionCell 
					form = {form}
					item={record} 
					editingId = {editingId}  
					onChange={() => loadTable()} 
					onEditing = {(val:string)=>{
						console.log('val ===>', val);
						setEditingId(val);
					}}
              
				/>
			),
		},
	];

	const [tableData, setTableData] = useState<Item[]>([]);
	const [tablePaginationOption, setTablePaginationOption] = useState<{total:number, curPage:number, pageSize:number}>({total:0, curPage:1, pageSize:20});

	// useEffect(()=>{
	// 	loadTable();
	// }, [tablePaginationOption.curPage]);

	useEffect(()=>{
		console.log('tags ===>', tags);
		loadTable();
	}, []);

	const loadTable = async (query='')=>{
		const {pageSize, curPage} = tablePaginationOption;
		const { body } = await getAllTags(pageSize * (curPage - 1), pageSize, query);
		setTableData(body);
		setTablePaginationOption({curPage, pageSize, total:body.total});
	};

	const onChange = (pagination:any, filters:any, sorter:any, extra:any) => {
		setTablePaginationOption({...tablePaginationOption, curPage: pagination.current});
	};

	const {pageSize, curPage, total} = tablePaginationOption;

	const onFinishSearch = (e:any) => {
		loadTable(e.target.value);
	};

	const onChangeSearchInput = (e:any) => {

		setQuery(e.target.value);
		
	};

	const mergedColumns = columns.map((col:any) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: Item) => ({
				form: form,
				record,
				inputType: (col.inputType)?col.inputType:'text',
				dataIndex: col.dataIndex,
				title: col.title,
				options: col.options,
				editing: (record.id == editingId),
			}),
		};
	});

	const handleOk = () => {
		setIsRequesting(true);
		createTag(createForm.getFieldsValue()).then(res=>{
			setIsRequesting(false);
			loadTable();
			setModalVisible(false);
			createForm.resetFields();

			//get all tags again
			getAllTagsSaga();

		}).catch(err=>{
			setIsRequesting(false);
		});
	};

	const handleCancel = () => {
		setModalVisible(false);
	};

	const layout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 18 },
	};

	return (
		<>
			<Card title = "Tags" extra = {[<div style = {{display:'flex'}}><Button type="primary" style = {{marginRight:24, marginLeft:'auto'}} onClick = {()=>{
				setModalVisible(true);
			}}>Add more</Button></div>]}>

				<div className ="table-header">
					<Input.Search style={{ width: '40%' }} onChange = {onChangeSearchInput} />
				</div>
				<Form form={form} component={false}>
					<Table 
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						columns={mergedColumns}
						dataSource={tableData.filter((ele)=>(ele.tag_name.toLowerCase().includes(query)))} 
						onChange = {onChange}  
						pagination={{ defaultPageSize: pageSize, total, showSizeChanger:true }}
					/>
				</Form>
			</Card>

			<Modal
				title="New"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="submit" type="primary" loading={isRequesting} onClick={handleOk}>
                    Ok
					</Button>,
				]}
			>
				<Form form={createForm} {...layout}>
					<Form.Item
						label="Tag Type"
						name="tag_type"
						tooltip={{ title: 'Tag Type', icon: <InfoCircleOutlined /> }}
						required
						rules={[{ required: true, message: 'required!' }]}
					>
						<Select>
							<Select.Option value = "shape">Shape</Select.Option>
							<Select.Option value = "state">State</Select.Option>
							<Select.Option value = "range">Range</Select.Option>
							<Select.Option value = "region">Region</Select.Option>
							<Select.Option value="ceil height">Ceil Height</Select.Option>
							<Select.Option value = "builder">Builder</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item
						label="Parent Tag"
						name="parent_tag"
						tooltip={{ title: 'Parent Tag', icon: <InfoCircleOutlined /> }}
					>
						<Select>
							<Select.Option value = {0}>None</Select.Option>
							{tags.filter((ele:any)=>ele.tag_type == 'state').map((ele:any)=>
								<Select.Option value = {ele.id}>{ele.tag_name}</Select.Option>
							)}
						</Select>
					</Form.Item>

					<Form.Item
						label="Tag Name"
						name="tag_name"
						tooltip={{ title: 'Tag Name', icon: <InfoCircleOutlined /> }}
						required
						rules={[{ required: true, message: 'required!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Tag Description"
						name="tag_description"
						tooltip={{ title: 'Tag desciption', icon: <InfoCircleOutlined /> }}
					>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default Index;