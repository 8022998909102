import React, { useState, useEffect, useRef } from 'react';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { getAll, deleteStyles, updateStyles, createStyles } from 'api/api-styles';
import { getStairTypes } from 'api/api-housetype';
import {
	Table,
	Tag,
	Space,
	Button,
	Modal,
	Form,
	Input,
	Card,
	Badge,
	InputNumber,
	Select,
	InputRef,
} from 'antd';
import { Link } from 'react-router-dom';
import classes from './style.module.scss';

import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import * as PricingAPI from 'api/api-pricing';
import useOptions from 'modules/options/options.hook';
import { PATHS } from 'constants/routes';

const { Option } = Select;

const confirm = Modal.confirm;

interface Item {
	id: number;
	tag_type: string;
	tag_name: string;
	tag_description: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	form: any;
	editing: boolean;
	required: boolean;
	disabled: boolean;
	dataIndex: string;
	title: any;
	inputType: 'number' | 'text' | 'photo-picker' | 'price' | 'text-select' | 'tag' | 'stair-select' | 'builder-select';
	record: Item;
	index: number;
	children: React.ReactNode;
	options?: any[];
}

const EditableCell: React.FC<EditableCellProps> = ({
	form,
	editing,
	dataIndex,
	title,
	inputType,
	disabled,
	record,
	index,
	children,
	options,
	required,
	...restProps
}) => {
	let inputNode = <Input disabled={disabled} />;

	if (inputType == 'number') {
		inputNode = <InputNumber min={0} />;
	}

	if (inputType == 'text-select') {
		inputNode = (
			<Select>
				{options ? options.map((ele: any) => <Option value={ele}>{ele}</Option>) : []}
			</Select>
		);
	}

	if (inputType == 'stair-select') {
		inputNode = (
			<Select>
				{options ? options.map((ele: any) => <Option value={ele.id}>{ele.stair_type}</Option>) : []}
			</Select>
		);
	}
	if(inputType == 'builder-select'){
		inputNode = <Select>{options?options.map((ele:any)=><Option value = {ele.id}>{ele.tag_name}</Option>):[]}</Select>;
	}
	if (inputType == 'tag') {
		inputNode = (
			<Select mode="multiple">
				{options ? options.map((ele) => <Option value={ele.id}>{ele.tag_name}</Option>) : []}
			</Select>
		);
	}
	if (inputType == 'price') {
		inputNode = <Input type="number" min={0} suffix="$" />;
	}
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};
const ActionCell = ({ item, editingId, onChange, onEditing, form }: any) => {
	const [isRequesting, setIsRequesting] = useState(false);
	const onDeleteBtn = (itemId:number) => {
		confirm({
			centered: true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps: {
				style: {
					backgroundColor: '#fff',
				},
			},
			cancelButtonProps: {
				style: {
					backgroundColor: '#0ab068',
					color: '#fff',
				},
			},
			onOk() {
				setIsRequesting(true);
				deleteStyles(itemId).then((res)=>{
					setIsRequesting(false);
					onChange();
				}).catch(err=>console.log);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const onClickEditBtn = () => {
		onEditing(item.id);
		form.setFieldsValue(item);
	};

	const onSave = async () => {
		setIsRequesting(true);
		const data = form.getFieldsValue();
		if (data.outer_stringer) {
			delete data.outer_stringer;
		}
		if (data.wall_stringer) {
			delete data.wall_stringer;
		}
		try {
			if (editingId > 0) {
				await updateStyles(editingId, data);
			} else {
				await createStyles(data);
			}
		} catch (err: any) {
			console.log(err);
		}
		setIsRequesting(false);
		onEditing(-1);
		form.resetFields();
		onChange();
	};

	const onCancel = () => {
		onEditing(-1);
	};

	if (editingId == item.id) {
		return (
			<Space size="middle">
				<Button loading={isRequesting} icon={<CheckCircleOutlined />} onClick={onSave} />
				<Button loading={isRequesting} icon={<CloseCircleOutlined />} onClick={onCancel} />
			</Space>
		);
	}

	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<EditOutlined />} onClick={onClickEditBtn} />
			<Button loading={isRequesting} icon={<DeleteOutlined />} onClick={()=>onDeleteBtn(item.id)} />
		</Space>
	);
};

const Index = () => {
	const [form] = Form.useForm();
	const [editingId, setEditingId] = useState(-1);
	const [tableData, setTableData] = useState<any>([]);
	const {
		options: { tags },
	} = useOptions();
	const [query, setQuery] = useState('');
	const [defaultStairTypes, setDefaultStairTypes] = useState<any[]>([]);

	const loadTable = () => {
		getAll().then((res) => {
			res.body.forEach((element: any) => {
				// const tempStateTags:any = [];
				// const tempRangeTags:any = [];
				// const tempShapeTags:any = [];
				// const tempShapeTagStrArr:any = [];
				// element.tags.split(',').forEach((tag_id:any) => {
				// 	tags.forEach((tag:any) => {
				// 		if(tag.id == tag_id && tag.tag_type == 'state'){
				// 			tempStateTags.push(tag.id);
				// 		}
				// 		if(tag.id == tag_id && tag.tag_type == 'range'){
				// 			tempRangeTags.push(tag.id);
				// 		}
				// 		if(tag.id == tag_id && tag.tag_type == 'shape'){
				// 			tempShapeTags.push(tag.id);
				// 			tempShapeTagStrArr.push(tag.tag_name);
				// 		}
				// 	});
				// });
				// element.state = tempStateTags;
				// element.range = tempRangeTags;
				// element.shape = tempShapeTags;
				// element.shapeArr = tempShapeTagStrArr;
			});
			setTableData(res.body);
		});
	};
	const columns = [
		{
			title: 'Styles',
			className: classes.tableBase,
			children: [
				{
					title: 'Builder',
					dataIndex: 'builder_id',
					key: 'builder_id',
					inputType:'builder-select',
					options: tags.filter((ele:any)=>ele.tag_type == 'builder'),
					editable: true,
					render: (text: string, item: any) => {
						const tag = tags.find((ele:any)=>ele.id == item.builder_id);
						if(tag){
							return tag.tag_name;
						}
						return '';
					}
				},
				{
					title: 'Code',
					dataIndex: 'code',
					key: 'code',
					editable: true,
					//fixed:'left',					
				},
				{
					title: 'Style',
					dataIndex: 'style',
					key: 'style',
					editable: true,
					//fixed:'left',
					sorter: (a: any, b: any) => ('' + a.style).localeCompare(b.style),
				},
				{
					title: 'Type',
					dataIndex: 'type',
					key: 'type',
					editable: true,
					//fixed:'left',
					sorter: (a: any, b: any) => ('' + a.type).localeCompare(b.type),
				},

				{
					title: 'Price',
					dataIndex: 'price',
					key: 'price',
					editable: true,
					//fixed:'left',
					sorter: (a: any, b: any) => a.price < b.price ? -1 : 1,
				},
				{
					title: 'Straight',
					dataIndex: 'straight',
					key: 'straight',
					editable: true,
					inputType: 'text-select',
					options: ['No', 'Yes'],
				},
				
				{
					title: 'U Shape Landing (A)',
					dataIndex: 'u_shape_landing_a',
					key: 'u_shape_landing_a',
					inputType: 'text-select',
					options: ['No', 'Yes'],
					editable: true,
				},
				{
					title: 'U Shape Landing (B)',
					dataIndex: 'u_shape_landing_b',
					key: 'u_shape_landing_b',
					inputType: 'text-select',
					options: ['No', 'Yes'],
					editable: true,
				},
				{
					title:'1-4 Transition',
					dataIndex:'transition_1_4',
					inputType: 'text-select',
					options: ['NO', 'YES'],
					key:'transition_1_4',
					editable:true
				},
				{
					title:'Straight - WR only',
					dataIndex:'straight_wr_only',
					inputType: 'text-select',
					options: ['NO', 'YES'],
					key:'straight_wr_only',
					editable:true
				},
				{
					title:'Straight Dim',
					dataIndex:'straight_dim',
					inputType: 'text-select',
					options: ['NO', 'YES'],
					key:'straight_dim',
					editable:true
				},
				{
					title:'Straight NB',
					dataIndex:'straight_nb',
					inputType: 'text-select',
					options: ['NO', 'YES'],
					key:'straight_nb',
					editable:true
				},
				{
					title:'Winders (A)(E)',
					dataIndex:'winders_a_e',
					inputType: 'text-select',
					options: ['NO', 'YES'],
					key:'winders_a_e',
					editable:true
				},
				{
					title: 'Stair Type',
					dataIndex: 'stair_type_id',
					inputType: 'stair-select',
					options: defaultStairTypes,
					key: 'stair_type_id',
					editable: true,
					render: (text: string, item: any) => <>{item.stair_type}</>,
				},
				{
					title: 'Outer Stringer',
					dataIndex: 'outer_stringer',
					key: 'outer_stringer',
					editable: true,
					disabled: true,
				},
				{
					title: 'Outer Stringer Colour',
					dataIndex: 'outer_stringer_colour',
					key: 'outer_stringer_colour',
					editable: true,
				},
				{
					title: 'Wall Stringer',
					dataIndex: 'wall_stringer',
					key: 'wall_stringer',
					editable: true,
					disabled: true,
				},
				{
					title: 'Wall Stringer Colour',
					dataIndex: 'wall_stringer_colour',
					key: 'wall_stringer_colour',
					editable: true,
				},
				{
					title: 'Tread Colour',
					dataIndex: 'tread_colour',
					key: 'tread_colour',
					editable: true,
				},
				{
					title: 'Riser Colour',
					dataIndex: 'riser_colour',
					key: 'riser_colour',
					editable: true,
				},
				{
					title: 'Grade',
					dataIndex: 'grade',
					key: 'grade',
					inputType: 'text-select',
					options: ['none', 'covergrade', 'staingrade', 'laminate'],
					editable: true,
				},
				{
					title: 'Balustrade',
					dataIndex: 'ballustrade',
					key: 'ballustrade',
					editable: true,
				},
				{
					title: 'Balustrade colour',
					dataIndex: 'ballustrade_colour',
					key: 'ballustrade_colour',
					editable: true,
				},
				{
					title: 'Handrail',
					dataIndex: 'handrail',
					key: 'handrail',
					editable: true,
				},
				{
					title: 'Handrail Colour',
					dataIndex: 'handrail_colour',
					key: 'handrail_colour',
					editable: true,
				},
				{
					title: 'Post',
					dataIndex: 'post',
					key: 'post',
					editable: true,
				},
				{
					title: 'Post Colour',
					dataIndex: 'post_colour',
					key: 'post_color',
					editable: true,
				},
				{
					title: 'Post Top',
					dataIndex: 'post_top',
					key: 'post_top',
					editable: true,
				},
				{
					title: 'Post Top Colour',
					dataIndex: 'post_top_colour',
					key: 'post_top_color',
					editable: true,
				},
				{
					title: 'Post Position',
					dataIndex: 'post_position',
					key: 'post_position',
					editable: true,
				},
				{
					title: 'Margins',
					dataIndex: 'margins',
					key: 'margins',
					editable: true,
				},
				{
					title: 'Capping',
					dataIndex: 'capping',
					key: 'capping',
					editable: true,
					inputType: 'text-select',
					options: [
						'None',
						'Step 1 Closed Riser',
						'Step 2 Closed Riser',
						'Step 3 Closed Riser',
						'Step 1 Open Riser',
						'Step 2 Open Riser',
						'Step 3 Open Riser',
					],
				},
				{
					title: 'Margins Colour',
					dataIndex: 'margins_colour',
					key: 'margins_colour',
					editable: true,
				},
				{
					title: 'Step 1 Feature',
					dataIndex: 'step_1_feature',
					key: 'step_1_feature',
					editable: true,
					required: false,
				},
				{
					title: 'Feature 1 Riser Grade',
					dataIndex: 'feature_1_riser_grade',
					key: 'feature_1_riser_grade',
					inputType:'text-select',
					options:['None','White Paint','Clear stain','Laminate'],
					editable: true,
					required: false,
				},
				{
					title: 'Feature 1 Tread Grade',
					dataIndex: 'feature_1_tread_grade',
					key: 'feature_1_tread_grade',
					inputType:'text-select',
					options:['None','White Paint','Clear stain','Laminate'],
					editable: true,
					required: false,
				},
				{
					title: 'Step 2 Feature',
					dataIndex: 'step_2_feature',
					key: 'step_2_feature',
					editable: true,
					required: false,
				},
				{
					title: 'Feature 2 Riser Grade',
					dataIndex: 'feature_2_riser_grade',
					key: 'feature_2_riser_grade',
					inputType:'text-select',
					options:['None','White Paint','Clear stain','Laminate'],
					editable: true,
					required: false,
				},
				{
					title: 'Feature 2 Tread Grade',
					dataIndex: 'feature_2_thread_grade',
					key: 'feature_2_thread_grade',
					inputType:'text-select',
					options:['None','White Paint','Clear stain','Laminate'],
					editable: true,
					required: false,
				},
				{
					title: 'Vic Ash Posts',
					dataIndex: 'vic_ash_posts',
					key: 'vic_ash_posts',
					editable: true,
					inputType: 'text-select',
					options: ['Yes', 'No'],
				},
				{
					title:'Stud Walls',
					dataIndex:'stud_walls',
					key:'stud_walls',
					editable: true,
					inputType: 'text-select',
					options: ['Y', 'N'],
				},
				{
					title:'Stud Price',
					dataIndex:'stud_price',
					key:'stud_price',
					editable: true
				}
			],
			editable: true,
		},

		{
			title: 'ACTIONS',
			className: classes.tableActions,
			children: [
				// {
				// 	title: 'Active',
				// 	dataIndex: 'active',
				// 	key: 'active',
				// 	inputType: 'text-select',
				// 	options: ['y', 'n'],
				// 	editable: true,
				// 	render: (text: string, item: any) =>
				// 		item.active == 'y' ? <Badge status="success" /> : <Badge status="default" />,
				// 
				// },
				{
					title: 'Action',
					key: 'action',
					//fixed:'right',
					render: (text: string, record: any) => (
						<ActionCell
							form={form}
							item={record}
							editingId={editingId}
							onChange={() => loadTable()}
							onEditing={(val: number) => {
								setEditingId(val);
								if (val < 0 && editingId == 0) {
									tableData.shift();
									setTableData([...tableData]);
								}
							}}
						/>
					),
				},
			],
			editable: true,
		},
	];

	const mapColumns = (col: any) => {
		if (!col.editable) {
			return col;
		}
		const newCol = {
			...col,
			onCell: (record: Item) => ({
				form: form,
				record,
				disabled: col.disabled,
				required: typeof col.required !== undefined ? col.required : true,
				inputType: col.inputType ? col.inputType : 'text',
				dataIndex: col.dataIndex,
				options: col.options,
				title: col.title,
				editing: record.id == editingId,
			}),
		};
		if (col.children) {
			newCol.children = col.children.map(mapColumns);
		}
		return newCol;
	};

	const mergedColumns = columns.map(mapColumns);

	useEffect(() => {
		async function asyncLoad() {
			// get default stair types
			try {
				const result = await getStairTypes();
				setDefaultStairTypes(result.body);
			} catch (error: any) {
				console.log(error.message);
			}
		}
		if (tags.length > 0) {
			loadTable();
		}
		asyncLoad();
	}, [tags]);

	const onChangeSearchInput = (e: any) => {
		setQuery(e.target.value);
	};

	const onCreateNewStyles = () => {
		setEditingId(0);
		const newData = {
			id: 0,
			style: 'New Style',
			code:''
		};
		if (tableData.findIndex((ele: any) => ele.id == 0) == -1) {
			setTableData([newData, ...tableData]);
		}
	};

	const onChangeFormValues = (value: any) => {
		if (value.stair_type) {
			// prefill form
			const stairType = defaultStairTypes.find((ele) => ele.id == value.stair_type);
			// console.log(out_stringer);
			form.setFieldsValue({
				outer_stringer: stairType.outer_stringer,
				wall_stringer: stairType.wall_stringer,
			});
		}
	};

	const ftableData = tableData.filter((ele: any) =>
		ele.code.toLowerCase().includes(query.toLowerCase()) || ele.style.toLowerCase().includes(query.toLowerCase()) || ele.id == 0,
	);

	return (
		<Card
			title="Styles"
			extra={
				<Button type="primary" onClick={onCreateNewStyles}>
					Add New
				</Button>
			}
		>
			<div className="table-header">
				<Input.Search style={{ width: '40%' }} onChange={onChangeSearchInput} />
			</div>
			<Form form={form} onValuesChange={onChangeFormValues}>
				<Table
					size="small"
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					bordered
					pagination = {
						{
							pageSize:100
						}
					}
					scroll={{ x: '3500px' }}
					columns={mergedColumns}
					dataSource={ftableData}
				/>
			</Form>
		</Card>
	);
};

export default Index;
