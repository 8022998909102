import React, { useEffect, useState } from 'react';
import { Table, Tag, Space, Button, Modal, Form, Input, Badge, Card, Row, Col, InputNumber, Select } from 'antd';
import { getTagDetail } from 'api/api-tags';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';


const Index = () => {

	const [form] = Form.useForm();
	const { tagId } = useParams();
	const [tagDetail, setTagDetail] = useState<any>({});
	const [isRquesting, setIsRequesting] = useState(false);
    

	const layout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 18 },
	};

	console.log('tagDetail ===>', tagDetail);

	useEffect(()=>{
		if(tagId){
			getTagDetail(tagId).then((res:any)=>{
				setTagDetail(res.body);
			}).catch((err:any)=>{
				console.log('error ==>', err);
			});
			
		}
	}, [tagId]);

	const submitForm = () => {
		form.submit();
	};


	return (
		<Card title = {parseInt(tagId || '0') > 0?tagDetail.title:'New Tag'} extra = {<Button type = "primary" onClick = {submitForm} loading = {isRquesting}>Save</Button>}>
			<Form form={form} {...layout}>
				<Form.Item
					label="Tag Type"
					name="tag_type"
					tooltip={{ title: 'Tag Type', icon: <InfoCircleOutlined /> }}
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Select>
						<Select.Option value = "shape">Shape</Select.Option>
						<Select.Option value = "state">State</Select.Option>
						<Select.Option value = "range">Range</Select.Option>
						<Select.Option value = "region">Region</Select.Option>
						<Select.Option value="ceil height">Ceil Height</Select.Option>
						<Select.Option value = "builder">Builder</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					label="Tag Name"
					name="tag_name"
					tooltip={{ title: 'Tag Name', icon: <InfoCircleOutlined /> }}
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Tag Description"
					name="tag_description"
					tooltip={{ title: 'Tag desciption', icon: <InfoCircleOutlined /> }}
				>
					<Input />
				</Form.Item>
			</Form>
		</Card>
	);
};

export default Index;